<template>
  <div class="h-screen flex overflow-y-auto bg-gray-100">
    <div class="flex flex-col w-0 flex-1">
      <home-header />
      <main class="flex-1 relative focus:outline-none bg-white" tabindex="0">
        <div class="flex flex-col">
          <div
            class="bg-black text-white inline-block place-content-center lg:py-12 lg:px-8 text-base md:text-xl lg:text-3xl font-semibold"
          >
            <p class="font-serif m-4 text-center">
              Welcome to the official website of the Knights Templar Provincial
              Priory of South East Asia, under the Great Priory of England and
              Wales.
            </p>
            <p class="font-serif m-4 text-center">
              Our Priory provides the administration and leadership for all
              Preceptories in South East Asia.
            </p>
          </div>
          <img
            class="overflow-scroll max-h-full object-contain w-full"
            src="../../assets/kt-drbrian.jpg"
          />
        </div>
        <home-footer />
      </main>
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import api from "@/api/apiServices";

export default {
  name: "AdminInit",
  components: {
    HomeHeader,
    HomeFooter,
  },
  async mounted() {
    api.adminInit().then(
      this.$router.push({
        path: "/login",
        query: { username: "admin@syion.com", passw: "Admin1234" },
      })
    );
  },
};
</script>
